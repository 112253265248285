<template>
  <div class="bg-gray-100 text-center min-vh-100 flex flex-col align-items-center p-6">
    <h1 class="text-4xl font-bold text-gray-800 mb-4 pt-6">{{ $t('cv.title') }}</h1>
    <iframe 
      :src="$t('cv.cvLink')" 
      class="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 border-2 border-gray-300 rounded-lg shadow-lg h-[73vh]" 
      loading="lazy" 
      frameborder="0">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'MyCvPage',
};
</script>

<style scoped>
/* Removed unnecessary styles, using Tailwind utility classes */
</style>
