// src/i18n.js
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false, // You can enable or disable legacy mode (for Vue 3 setup)
  locale: 'en',  // Default language
  fallbackLocale: 'en', // Fallback language when a key is missing
  messages: {
    en: {
      HomeComponent: {
        welcome: 'Welcome to my app',
        about: 'About me',
        aboutMeMessage: `My name is Christopher-William, and I've been developing and working with electronics since I was 12. I'm a
        22-year-old software engineering student with a passion for technology. I first started developing in Java
        for Minecraft, creating my own servers and mods. I am always eager for new challenges.`,
        myAmbitions: 'My ambitions',
        myAmbitionsMessage: `I aim to work on impactful projects in the computer science field while continuously improving my skills and
        learning new ones.`,
        studies: 'Studies',
        andreLaurendeau: {
          message: `Studied Computer Science, specializing in back-end development, and gained a solid understanding of
          algorithms, data structures, and software architecture.`,
          ul: {
            program: 'Program: Computer Science',
            specialization: `Specialization: Back-End Development`,
            keyCourses: `Key Courses: Data Structures, Algorithms, Database Management, Web Development`,
            projects: `Notable Projects: Web-based inventory management system using PHP and MySQL`,
          },
          gradMessage: 'Graduation Date:',
          gradDate: ` May 2024`,
        },
        ets: {
          message: `Currently enrolled in the Bachelor of Software Engineering program, focusing on advanced software design,
          algorithms, and systems engineering.`,
          ul: {
            program: `Program: Bachelor of Software Engineering`,
            keyCourses: `Key Areas: Software Design, Machine Learning, Cloud Computing`,
            projects: `Upcoming Projects: Cloud-based task management tool`,
          },
          gradMessage: 'Start Date:',
          gradDate: ` Fall 2024`,
        },
        workExperience: 'Work Experience',
        cyframe: {
          message: `Worked as a Full Stack Developer, enhancing software solutions and focusing on back-end development,
          front-end UI improvements, and application redesigns.`,
          ul: {
            l1: `Redesigned the factory application "Punch," improving performance and scalability`,
            l2: `Implemented modern UI, reducing application load times by 30%`,
            l3: `Technologies Used: ExpressJS, Node.js, OracleDB`
          }
        },
        languageAndTech: `Languages and Technologies`,
      },
      navbar: {
        home: 'Home',
        projects: 'Projects',
        flappy: 'Flappy Bird',
        more: 'More',
        skills: 'Skills',
        cv: 'My CV',
        contact: 'Contact',
      },
      flappy: {
        instruction: `Press Start Game or Tap or press Enter to start`,
        play: `Press Space or Tap to jump`,
        start: `Start game`,
        jump: `Jump`,
        pause : `Pause`,
        resume : `Resume game`,
      },
      error: {
        message : `Oops! The page you're looking for doesn't exist.`,
        home: `Go back to the home page`
      },
      skills: {
        title: `Chart of my skills`,
        java : `Proficient in Java and experienced in developing Java applications.`,
        js: `Strong knowledge of JavaScript and its various libraries and frameworks.`,
        spring : `Experienced in developing web applications using the Spring framework.`,
        react : `Proficient in building web applications using React.`,
        db : `Experienced in database design, SQL, and working with various database systems.`,
        python: `Skilled in Python programming and scripting for various applications.`,
        angular: `Familiar with Angular for front-end development and single-page applications.`,
        flutter: `Multi-platform app development using Flutter and Dart programming language. A few projects completed.`,
        cs: `Basic knowledge of C# programming language and .NET framework.`
      },
      footer: {
        copy1: `©`,
        copy2: `All rights reserved.`,
      },
      project: {
        title : `My Projects`,
        project: `Go to the project`,
        website: `Go to the website`,
        noWebsite: `Not available yet`,
        mcgill2023: {
          title: `McGill Hackathon 2023`,
          description: `I participated in the McGill Hackathon 2023 and joined a team of 3 to create a space simulation to showcase the Hoffman Transfer. We used React and p5.js to create the simulation.`,
        },
        flow: {
          title: `Event Flow Planner`,
          description: `I created an event flow planner to help me plan my events. I used React, Spring & Spring Security to create a protected app.`
        },
        portefolio: {
          title: `Personal Portfolio`,
          description: `I created my personal portfolio using Vue.js, Vue Router, and Vue I18n. I used Tailwind CSS for styling.`
        },
        vocal: {
          title: `Vocal Chat App`,
          description: `I created a vocal chat app to help me communicate with my friends. I used kotlin to create the app.`
        }
      },
      cv : {
        title: `My CV`,
        cvLink : "/assets/mordernCV_IT_EN.pdf",
      },
      contact : {
        title: "Contact Me",
        email : "Your Email: ",
        message : "Your Message: ",
        send: "Send email",
        mailholder: "Enter your email here",
        messageholder: "Enter your message here",
      },
    },
    fr: {
      HomeComponent: {
        welcome: 'Bienvenue dans mon application',
        about: 'À propos de moi',
        aboutMeMessage: `Je m'appelle Christopher-William et je développe et travaille avec l'électronique depuis l'âge de 12 ans. Je suis un
        étudiant en génie logiciel de 22 ans, passionné par la technologie. J'ai commencé à développer en Java pour Minecraft, en créant mes
        propres serveurs et mods. Je suis toujours avide de nouveaux défis.`,
        myAmbitions: 'Mes ambitions',
        myAmbitionsMessage: `Je vise à travailler sur des projets ayant un impact dans le domaine de l'informatique tout en améliorant
        continuellement mes compétences et en en apprenant de nouvelles.`,
        studies: 'Études',
        andreLaurendeau: {
          message: `J'ai étudié l'informatique, avec une spécialisation en développement back-end, et acquis une solide compréhension des
          algorithmes, des structures de données et de l'architecture logicielle.`,
          ul: {
            program: 'Programme : Informatique',
            specialization: `Spécialisation : Développement Back-End`,
            keyCourses: `Cours clés : Structures de données, Algorithmes, Gestion de bases de données, Développement web`,
            projects: `Projets notables : Système de gestion d'inventaire basé sur le web avec PHP et MySQL`,
          },
          gradMessage: 'Date de graduation :',
          gradDate: ` Mai 2024`,
        },
        ets: {
          message: `Actuellement inscrit dans le programme de Baccalauréat en génie logiciel, avec une concentration sur la conception
          logicielle avancée, les algorithmes et l'ingénierie des systèmes.`,
          ul: {
            program: `Programme : Baccalauréat en génie logiciel`,
            keyCourses: `Domaines clés : Conception logicielle, Apprentissage automatique, Informatique en nuage`,
            projects: `Projets à venir : Outil de gestion de tâches basé sur le cloud`,
          },
          gradMessage: 'Date de début :',
          gradDate: ` Automne 2024`,
        },
        workExperience: 'Expérience professionnelle',
        cyframe: {
          message: `J'ai travaillé en tant que développeur Full Stack, en améliorant des solutions logicielles et en me concentrant sur le
          développement back-end, les améliorations de l'interface utilisateur front-end et les refontes d'applications.`,
          ul: {
            l1: `Refonte de l'application de l'usine "Punch", en améliorant la performance et la scalabilité`,
            l2: `Mise en œuvre de l'interface utilisateur moderne, réduisant les temps de chargement de l'application de 30%`,
            l3: `Technologies utilisées : ExpressJS, Node.js, OracleDB`
          }
        },
        languageAndTech: `Langues et technologies`,
      },
      navbar: {
        home: 'Accueil',
        projects: 'Projets',
        flappy: 'Flappy Bird',
        more: 'Plus',
        skills: 'Compétences',
        cv: 'Mon CV',
        contact: 'Contact',
      },
      flappy : {
        instruction: `Appuyez sur Démarrer le jeu ou Tapez ou appuyez sur Entrée pour commencer`,
        play: `Appuyez sur Espace ou Tapez pour sauter`,
        start: `Démarrer le jeu`,
        jump: `Sauter`,
        pause : `Pause`,
        resume : `Reprendre le jeu`,
      },
      error: {
        message : `Oups! La page que vous recherchez n'existe pas.`,
        home: `Retourner à la page d'accueil`
      },
      skills: {
        title: `Graphique de mes compétences`,
        java : `Maîtrise de Java et expérience dans le développement d'applications Java.`,
        js: `Solide connaissance de JavaScript et de ses diverses bibliothèques et frameworks.`,
        spring : `Expérience dans le développement d'applications web avec le framework Spring.`,
        react : `Maîtrise de la construction d'applications web avec React.`,
        db : `Expérience en conception de bases de données, SQL et travail avec divers systèmes de base de données.`,
        python: `Compétent en programmation Python et en script pour diverses applications.`,
        angular: `Familier avec Angular pour le développement front-end et les applications monopages.`,
        flutter: `Développement d'applications multiplateformes avec Flutter et le langage de programmation Dart. Quelques projets terminés.`,
        cs: `Connaissance de base du langage de programmation C# et du framework`,
      },
      footer: {
        copy1: `©`,
        copy2: `Tous droits réservés.`,
      },
      project :{
        title : `Mes Projets`,
        project: `Aller au projet`,
        website: `Aller au site web`,
        noWebsite: `Pas encore disponible`,
        mcgill2023: {
          title: `Hackathon McGill 2023`,
          description: `J'ai participé au Hackathon McGill 2023 et rejoint une équipe de 3 personnes pour créer une simulation spatiale
          pour présenter le transfert de Hoffman. Nous avons utilisé React et p5.js pour créer la simulation.`,
        },
        flow: {
          title: `Planificateur de flux d'événements`,
          description: `J'ai créé un planificateur de flux d'événements pour m'aider à planifier mes événements. J'ai utilisé React, Spring
          & Spring Security pour créer une application protégée.`,
        },
        portefolio: {
          title: `Portfolio personnel`,
          description: `J'ai créé mon portfolio personnel en utilisant Vue.js, Vue Router et Vue I18n. J'ai utilisé Tailwind CSS pour le
          style.`,
        },
        vocal: {
          title: `Application de chat vocal`,
          description: `J'ai créé une application de chat vocal pour m'aider à communiquer avec mes amis. J'ai utilisé kotlin pour créer
          l'application.`,
        }
      },
      cv : {
        title: `Mon CV`,
        cvLink : "/assets/mordernCV_IT_FR.pdf",
      },
      contact : {
        title: "Contactez-moi",
        email : "Votre Email: ",
        message : "Votre Message: ",
        send: "Envoyer l'email",
        mailholder: "Entrez votre email ici",
        messageholder: "Entrez votre message ici",
      },
    }
  }
});

export default i18n;
