<template>
  <div class="bg-gray-100 text-center min-h-screen flex flex-col py-6">
    <div class="w-full px-4 lg:px-12 pt-6">
      <!-- Title and Navigation -->
      <div class="mb-8">
        <h1 class="text-4xl font-bold text-gray-800 mb-4">{{ $t('project.title') }}</h1>
        <div class="flex justify-between items-center max-w-lg mx-auto">
          <button
            class="prev-btn w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-r from-green-300 via-grey-500 to-green-500 shadow-lg hover:scale-110 transform transition-transform"
            @click="prevSlide"
            aria-label="Previous Slide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-6 h-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            class="next-btn w-12 h-12 flex items-center justify-center rounded-full bg-gradient-to-r from-green-500 via-grey-500 to-green-300 shadow-lg hover:scale-110 transform transition-transform"
            @click="nextSlide"
            aria-label="Next Slide"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-6 h-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- Project Carousel -->
      <div class="overflow-hidden relative max-w-screen-lg mx-auto shadow-lg rounded-md">
        <!-- Outer wrapper for smooth scrolling -->
        <div
          class="flex transition-transform duration-700 ease-in-out rounded-md"
          :style="{ transform: `translateX(-${activeIndex * 100}%)` }"
        >
          <div
            v-for="(project, index) in allProjects"
            :key="index"
            class="flex-shrink-0 w-full max-w-full bg-white rounded-lg overflow-hidden rounded-md"
          >
            <div class="p-6 mx-2"> <!-- Margins on content -->
              <h2 class="text-xl font-semibold text-gray-800">{{ $t('project.' + project.title + '.title') }}</h2>
              <p class="text-gray-600 mb-4">{{ $t('project.' + project.title + '.description') }}</p>
              <img
                v-if="project.image"
                :src="project.image"
                alt="Project image"
                class="w-full h-60 object-cover rounded-lg mb-4"
              />
              <div class="flex justify-around mt-4">
                <button
                  @click="goToProject(project.link)"
                  class="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition"
                >
                  {{ $t('project.project') }}
                </button>
                <button
                  @click="project.websiteLink !== 'Not available yet' ? goToWebsite(project.websiteLink) : null"
                  class="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition"
                >
                  {{ project.websiteLink != "Not available yet" ? $t('project.website') : $t('project.noWebsite') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectsPage",
  data() {
    return {
      allProjects: [
        {
          title: "mcgill2023",
          link: "https://github.com/PureLeafGreen/hackaton-mcgill-physics-2023",
          websiteLink:
            "https://echoingekko00.github.io/mcgill-physics-hackathon-2023/",
          image: require("../../assets/mcgillProject.png"),
        },
        {
          title: "flow",
          link: "https://github.com/echoingekko00/applicationTache",
          websiteLink: "Not available yet",
        },
        {
          title: "portefolio",
          link: "https://github.com/echoingekko00/portefolioVUEJS",
          websiteLink: "https://christopherwilliam.ca/",
          image: require("../../assets/portefolio.png"),
        },
        {
          title: "vocal",
          link: "https://github.com/echoingekko00/projet3Veille",
          websiteLink: "Not available yet",
        },
      ],
      activeIndex: 0,
    };
  },
  methods: {
    nextSlide() {
      this.activeIndex = (this.activeIndex + 1) % this.allProjects.length;
    },
    prevSlide() {
      this.activeIndex =
        (this.activeIndex - 1 + this.allProjects.length) %
        this.allProjects.length;
    },
    goToProject(link) {
      window.open(link, "_blank");
    },
    goToWebsite(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.min-h-screen {
  min-height: 100vh;
}

.overflow-hidden {
  width: 100%;
  position: relative;
}

img {
  max-height: 60%;
}

/* Fix slide transition spacing */
.flex-shrink-0 {
  flex: 0 0 auto;
  width: calc(100%); /* Adjust for margin (mx-2) */
}

.transition-transform {
  will-change: transform;
}
</style>
