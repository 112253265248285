<template>
    <ErrorComponent />
</template>

<script>
    import ErrorComponent from '../ErrorComponent.vue';
    export default {
        name: 'ErrorPage',
        components: { ErrorComponent }
    }
</script>