import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory} from 'vue-router';
import HomeComponent from './components/HomeComponent.vue'
import FlappyBirdPage from './components/pages/FlappyBirdPage.vue'
import ProjectsPage from './components/pages/ProjectsPage.vue'
import MyCvPage from './components/pages/MyCvPage.vue';
import ContactPage from './components/pages/ContactPage.vue'
import ErrorPage from './components/pages/ErrorPage.vue'
import './assets/tailwind.css';
import i18n from './i18n';


import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import SkillsPage from './components/pages/skillsPage.vue';

const routes = [
    {
      path: "/",
      name: "Home",
      component: HomeComponent,
    },
    {
      path: "/projects",
      name : "Projects",
      component: ProjectsPage,
    },
    {
      path: "/minigame1",
      name : "FlappyBird",
      component: FlappyBirdPage,
    },
    {
      path : "/skills",
      name : "skills",
      component: SkillsPage,

    },
    {
      path : "/myCV",
      name : "MyCV",
      component: MyCvPage,
    },
    {
      path : "/contact",
      name : "Contact",
      component: ContactPage,
    },
    {
      path: '/:pathMatch(.*)*',
      name: "Error",
      component: ErrorPage,
    },
];
  
const router = createRouter({
history: createWebHashHistory(),
routes,
});

router.beforeEach((to) => {
  document.title = "Chris' Portfolio - " + to.name;
});

createApp(App).use(router).use(i18n).mount('#app')
