<template class="app">
  <link
    href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1"
    rel="stylesheet"
    type="text/css"
  />
  <link href="css/bootstrap.css" rel="stylesheet" />
  <link rel="icon" type="image/png" href="./assets/wolf1024x1024.png" />

  <header class="sticky top-0 z-50">
    <NavbarPage />
  </header>
  
  <transition
    enter-active-class="transition-transform transition-opacity duration-700 ease-in-out"
    leave-active-class="transition-transform transition-opacity duration-500 ease-in-out"
    enter-class="opacity-0 translate-y-10"
    enter-to-class="opacity-100 translate-y-0"
    leave-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-10"
  >
    <div>
      <router-view class="view" style="height: 100%;" />
    </div>
  </transition>
  
  <FooterComponent />
  
  <!-- Language Switcher Button at the bottom-left -->
  <div @click="toggleLanguage" class="language-button">
    {{ $i18n.locale === 'fr' ? 'FR' : 'EN' }}
  </div>
</template>

<script>
import NavbarPage from "@/NavbarPage.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  name: "App",
  components: { NavbarPage, FooterComponent },
  methods: {
    toggleLanguage() {
      const newLocale = this.$i18n.locale === 'en' ? 'fr' : 'en';
      this.$i18n.locale = newLocale; // Toggle between 'en' and 'fr'
    }
  }
};
</script>

<style>
html {
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;
}

.app {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  scrollbar-width: none;
}

/* Position the language button at the bottom left of the screen */
.language-button {
  position: fixed;
  bottom: 15px;
  left: 15px;
  background-color: #4CAF5098;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.language-button:hover {
  background-color: #45a049;
}
</style>
