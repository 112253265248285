<template>
  <div class="bg-gray-100 text-center min-vh-100 flex flex-col align-items-center p-6">
    <h1 class="text-4xl font-bold text-gray-800 mb-4 pt-6">{{ $t('contact.title') }}</h1>
    <form class="w-full max-w-lg bg-white shadow-md rounded-lg p-6 space-y-4" @submit.prevent="sendEmail">
      <div class="space-y-2">
        <label for="email" class="block text-gray-700 font-medium">{{ $t('contact.email')}}</label>
        <input 
          type="email" 
          id="email" 
          v-model="senderEmail" 
          class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
          :placeholder="$t('contact.mailholder')"
          required 
        />
      </div>
      <div class="space-y-2">
        <label for="message" class="block text-gray-700 font-medium">{{ $t('contact.message') }}</label>
        <textarea 
          id="message" 
          v-model="emailMessage" 
          rows="4" 
          class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-y"
          :placeholder="$t('contact.messageholder')"
          required 
        ></textarea>
      </div>
      <button 
        type="submit" 
        class="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition">
        {{ $t('contact.send') }}
      </button>
    </form>
    <h1 id="emailFeedback" class="mt-4 text-lg font-semibold"></h1>
    <div class="flex justify-center mt-8 space-x-4">
      <a href="https://github.com/PureLeafGreen" target="_blank" rel="noopener">
        <img class="h-10 w-10" alt="GitHub" src="@/assets/github.png" />
      </a>
      <a href="https://www.instagram.com/lechrist666?igsh=MWd1Z3BwcWM0NHIydw==" target="_blank" rel="noopener">
        <img class="h-10 w-10" alt="Instagram" src="@/assets/Instagram.png" />
      </a>
      <a href="https://www.linkedin.com/in/christopher-william-archambault-bouffard/" target="_blank" rel="noopener">
        <img class="h-10 w-10" alt="LinkedIn" src="@/assets/linkedin.png" />
      </a>
      <a href="https://www.facebook.com/JesusChrisDeNazareth/" target="_blank" rel="noopener">
        <img class="h-10 w-10" alt="Facebook" src="@/assets/facebook.png" />
      </a>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import emailjs from "emailjs-com";

export default {
  name: "ContactPage",
  data() {
    return {
      senderEmail: "",
      emailMessage: "",
    };
  },
  methods: {
    sendEmail() {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.senderEmail || !this.emailMessage) {
        this.emailErrorMessage("Please fill out all fields.");
        return;
      }
      if (!emailRegex.test(this.senderEmail)) {
        this.emailErrorMessage("Please enter a valid email address.");
        return;
      }
      const templateParams = {
        senderEmail: this.senderEmail,
        emailMessage: this.emailMessage,
      };

      emailjs
        .send("service_ikj6qlk", "template_pcojkiv", templateParams, "OZhcwc3SQlsH71WbZ")
        .then((response) => {
          console.log("Email sent:", response);
          this.emailFeedback(false);
          this.senderEmail = "";
          this.emailMessage = "";
        })
        .catch((error) => {
          console.error("Failed to send email:", error);
          this.emailFeedback(true);
        });
    },
    emailErrorMessage(emailErrorMessage) {
      document.getElementById("emailFeedback").innerHTML = emailErrorMessage;
      document.getElementById("emailFeedback").style.visibility = "visible";
      document.getElementById("emailFeedback").style.color = "red";
      setTimeout(() => {
        document.getElementById("emailFeedback").style.visibility = "hidden";
      }, 5000);
    },
    emailFeedback(error) {
      const feedbackElement = document.getElementById("emailFeedback");
      if (error) {
        feedbackElement.innerHTML = "Failed to send email. Please try again later.";
        feedbackElement.style.color = "red";
      } else {
        feedbackElement.innerHTML = "Email sent successfully! I will get back to you as soon as possible.";
        feedbackElement.style.color = "green";
      }
      feedbackElement.style.visibility = "visible";
      setTimeout(() => {
        feedbackElement.style.visibility = "hidden";
      }, 5000);
    },
  },
};
</script>
