<template>
    <div class="error-page flex items-center justify-center min-h-screen bg-gradient-to-r from-green-100 via-grey-300 to-green-400 text-white">
      <div class="text-center">
        <h1 class="text-9xl font-extrabold tracking-tight mb-6 animate-bounce">404</h1>
        <p class="text-2xl md:text-3xl font-light mb-8">
          {{ $t('error.message') }}
        </p>
        <button
          class="px-6 py-3 bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition"
          @click="goBack"
        >
        {{ $t('error.home') }}
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ErrorPage",
    methods: {
      goBack() {
        this.$router.push("/");
      },
    },
  };
  </script>
  
  <style scoped>
  .error-page {
    font-family: 'Inter', sans-serif;
  }
  
  h1 {
    text-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  button {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  }
  </style>
  