<template>
  <div class="bg-gray-100 text-center min-vh-100 flex flex-col py-6">
    <div id='flappy' class="flex flex-col items-center justify-center pt-6">
      <div class="text-center mb-4">
        <h1 class="text-4xl font-bold text-gray-800 mb-4">Flappy Bird</h1>
        <p v-if="!gameStarted" class="text-lg text-gray-700">
          {{ $t('flappy.instruction') }}
        </p>
        <p v-if="gameStarted" class="text-lg text-gray-700">
          {{ $t('flappy.play') }}
        </p>
        <div class="space-x-2 mt-4">
          <button
            class="px-4 py-2 font-semibold text-black bg-lime-500 rounded hover:bg-lime-600"
            v-if="!gameStarted"
            @click="startGame"
          >
            {{  $t('flappy.start') }}
          </button>
          <button
            class="px-4 py-2 font-semibold text-black bg-yellow-500 rounded hover:bg-yellow-600"
            v-if="gameStarted"
            @click="jump"
          >
            {{ $t('flappy.jump') }}
          </button>
          <button
            class="px-4 py-2 font-semibold text-black bg-red-500 rounded hover:bg-red-600"
            v-if="!paused"
            @click="pauseGame"
          >
            {{ $t('flappy.pause') }}
          </button>
          <button
            class="px-4 py-2 font-semibold text-black bg-lime-500 rounded hover:bg-lime-600"
            v-if="paused"
            @click="resumeGame"
          >
            {{ $t('flappy.resume') }}
          </button>
        </div>
      </div>
      <div
        id="flappy-bird-game"
        class="relative w-3/4 h-[60vh] max-h-[800px]border-4 border-gray-700 bg-gradient-to-b from-sky-300 to-sky-400 overflow-hidden rounded-lg z-0"
        @keydown.enter="startGame"
        @mousedown.left="jump"
        @keydown.space="jump"
        @touchstart="jump"
        @mouseenter="focusGame"
        tabindex="0"
      >
      <div
        class="absolute bg-no-repeat bg-center bg-contain bird"
        :style="{
          top: birdY + 'px',
          left: birdX + 'px',
          width: birdSize + 'px',
          height: birdSize + 'px',
          backgroundSize: '120% 100%',
          backgroundImage: `url(${require('../assets/flappyBird1.png')})`
        }"
      ></div>

        <div class="pipes">
          <div
            v-for="(pipe, index) in pipes"
            :key="index"
            class="absolute border-4 border-gray-700 bg-gradient-to-b from-green-300 to-green-400 pipe"
            :style="{ height: pipe.height + 'px', left: pipe.left + 'px', top: pipe.top + 'px', width: pipeWidth + 'px' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      birdY: window.innerWidth < 600 ? 100 : 200, // Initial bird position based on screen size
      birdX: window.innerWidth < 600 ? 50 : 100, // Initial bird position based on screen size
      pipeWidth: window.innerWidth < 600 ? 25 : 50,
      birdVelocity: 0, // Bird's vertical velocity
      pipes: [], // Array to store pipe positions
      gameStarted: false, // Add a game started flag
      paused: false, // Add a paused flag
    };
  },
  methods: {
    startGame() {
      if (!this.gameStarted) {
        if (!this.paused)
        this.gameStarted = true;
        this.createPipe();
        this.gameInterval = setInterval(() => {
          this.moveBird();
          this.movePipes();
          this.checkCollision();
        }, 20);
        this.pipeInterval = setInterval(() => {
          this.createPipe();
        }, 1500);
      }
    },
    focusGame() {
      if (!this.gameStarted) {
        this.$el.focus();
      }
    },
    blurGame() {
      if (!this.gameStarted) {
        this.$el.blur();
        return false;
      }
      return true;
    },
    pauseGame() {
      clearInterval(this.gameInterval);
      clearInterval(this.pipeInterval);
      this.paused = true;
    },
    resumeGame() {
      this.gameInterval = setInterval(() => {
        this.moveBird();
        this.movePipes();
        this.checkCollision();
      }, 20);
      this.pipeInterval = setInterval(() => {
        this.createPipe();
      }, 1500);
      this.paused = false;
    },
    jump() {
      if (this.gameStarted) {
        this.birdVelocity = -6; // Adjust as needed
      }
    },
    moveBird() {
      this.birdY += this.birdVelocity;
      if (window.innerWidth < 600) {
        this.birdVelocity += 0.4; // Adjust gravity as needed
      }
      else {
        this.birdVelocity += 0.5; // Adjust gravity as needed
      }
      // Prevent bird from moving out of the top boundary
      if (this.birdY < 0) {
        this.birdY = 0;
        this.birdVelocity = 0;
      }
    },
    movePipes() {
      for (let i = 0; i < this.pipes.length; i++) {
        this.pipes[i].left -= 2; // Adjust pipe speed as needed
        if (this.pipes[i].left + (window.innerWidth < 600 ? 25 : 50) < 0) {
          // Remove off-screen pipes
          this.pipes.splice(i, 1);
          i--;
        }
      }
    },
    createPipe() {
      const gapHeight = (window.innerWidth < 600 ? 95 : 150);
      const maxPipeHeight = (window.innerHeight * 0.6) - gapHeight;
      const topPipeHeight = Math.floor(Math.random() * (maxPipeHeight - 50)) + 50;
      const bottomPipeHeight = maxPipeHeight - topPipeHeight;

      const topPipe = {
        height: topPipeHeight,
        left: window.innerWidth < 600 ? window.innerWidth : 400,
        top: 0,
      };

      const bottomPipe = {
        height: bottomPipeHeight,
        left: window.innerWidth < 600 ? window.innerWidth : 400,
        top: topPipeHeight + gapHeight,
      };

      this.pipes.push(topPipe, bottomPipe);
    },
    checkCollision() {
      if (this.birdY > document.querySelector('#flappy-bird-game').clientHeight) {
        // Bird hits the ground
        alert('Game Over');
        clearInterval(this.gameInterval);
        clearInterval(this.pipeInterval);
        this.gameStarted = false;
        window.location.reload();
      }
      for (let i = 0; i < this.pipes.length; i++) {
        const pipe = this.pipes[i];
        const birdRight = this.birdX + (window.innerWidth < 600 ? 32 : 47);
        const birdBottom = this.birdY + (window.innerWidth < 600 ? 32 : 47);
        const pipeRight = pipe.left + (window.innerWidth < 600 ? 32 : 47);

        if (
          birdRight > pipe.left &&
          this.birdX < pipeRight &&
          this.birdY < pipe.top + pipe.height &&
          birdBottom > pipe.top
        ) {
          // Collision detected
          alert('Game Over');
          clearInterval(this.gameInterval);
          clearInterval(this.pipeInterval);
          this.gameStarted = false;
          window.location.reload();
        }
      }
    },
  },
  mounted() {
    if (this.blurGame()) {
      this.$el.focus();
    }
  },
};
</script>

<style scoped>

.bird {
  position: absolute;
  width: 50px;
  height: 50px;
}

.pipe {
  position: absolute;
  width: 50px;
  background-color: green;
}

@media screen and (max-width: 600px) {
  .bird {
    width: 35px !important;
    height: 35px !important;
  }

  .pipe {
    width: 35px !important;
  }
}
</style>
