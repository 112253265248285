<template>
    <div class="bg-secondary">
        <div class="footer-copyright text-center py-3">{{ $t('footer.copy1') }}{{ actualYear }}
            <router-link to="/contact" style="color: lime !important;">Christopher-William.</router-link>
             {{ $t('footer.copy2') }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterComponent',
        data() {
            return {
                actualYear: new Date().getFullYear()
            };
        }
    }
</script>

<style>
</style>