<template>
    <FlappyBirdComponent/>
</template>

<script>
    import FlappyBirdComponent from '../FlappyBirdComponent.vue';
    export default {
        name: 'FlappyBirdPage',
        components: {
            FlappyBirdComponent,
        },
    };

</script>

<style>
</style>