<template>
  <nav class="bg-gray-800 p-2 flex flex-col md:flex-row md:h-20 items-center">
    <div class="flex justify-between max-lg:w-full items-center">
      <router-link class="navbar-brand" to="/">
        <img class="rounded-full" id="home-profile" height="85" width="85" alt="Image of creator" src="../assets/wolf1024x1024.png" />
      </router-link>
      <button @click="toggleNavbar" class="lg:hidden text-white">
        <span class="navbar-toggler-icon">☰</span>
      </button>
    </div>

    <!-- Main Navigation Links -->
    <div :class="isNavbarOpen ? 'flex flex-col items-center' : 'hidden lg:flex lg:items-center'" class="w-full">
      <ul class="flex flex-col lg:flex-row lg:space-x-6 space-y-4 lg:space-y-0 mb-0 items-center">
        <li class="nav-item" id="home">
          <router-link @click="closeNavBarAndMore" class="nav-link text-white hover:bg-gray-700 px-4 py-2 rounded-md" to="/">
            {{ $t('navbar.home') }}
          </router-link>
        </li>
        <li class="nav-item" id="projects">
          <router-link @click="closeNavBarAndMore" to="/projects" class="nav-link text-white hover:bg-gray-700 px-4 py-2 rounded-md">
            {{ $t('navbar.projects') }}
          </router-link>
        </li>
        <li class="nav-item" id="minigame1">
          <router-link @click="closeNavBarAndMore" to="/minigame1" class="nav-link text-white hover:bg-gray-700 px-4 py-2 rounded-md">
            {{ $t('navbar.flappy') }}
          </router-link>
        </li>
        <li class="nav-item relative" id="dropdown">
          <button @click="toggleMore" class="text-white hover:bg-gray-700 px-4 py-2 rounded-md">
            {{ $t('navbar.more') }}
          </button>
          <div v-if="moreOpen" class="absolute bg-gray-700 border rounded-lg mt-2 w-48 z-50">
            <ul class="list-none p-2">
              <dropdown-item 
                v-for="item in dropdown_items" 
                :key="item[0]" 
                @click="closeNavBarAndMore" 
                :link="item[1]" 
                class="text-white hover:bg-gray-600 px-4 py-2 rounded-md">
                {{ $t('navbar.' + item[0]) }}
              </dropdown-item>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import DropdownItem from "./dropdown/DropdownItem.vue";

export default {
  data() {
    return {
      isNavbarOpen: false,
      moreOpen: false,
      dropdown_items: [
        ["skills", "/skills"],
        ["cv", "/myCV"],
        ["contact", "/contact"],
      ],
    };
  },
  methods: {
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;

      if (this.isNavbarOpen) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    toggleMore() {
      this.moreOpen = !this.moreOpen;
    },
    closeNavBarAndMore() {
      this.isNavbarOpen = false;
      this.moreOpen = false;
      document.removeEventListener("click", this.handleClickOutside);
    },
    handleClickOutside(event) {
      const navbar = this.$el;
      if (!navbar.contains(event.target)) {
        this.closeNavBarAndMore();
      }
    },
  },
  name: "NavbarPage",
  components: {
    DropdownItem,
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
}

.navbar-toggler-icon {
  font-size: 2rem;
}
</style>
