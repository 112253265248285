<template>
  <div id="programmingLanguage" class="bg-gray-100 min-vh-100 text-center items-center pt-6">
    <div class="container w-full px-4 lg:px-12">
      <div class="d-flex flex-column h-100 align-items-center justify-content-center pt-6">
        <h1 class="text-4xl font-bold text-gray-800 mb-4 ">{{ $t('skills.title') }}</h1>
        <div class="skillBarContainer pb-4">
          <div class="skill-bar" v-for="skill in skills" :key="skill.name">
            <div class="skill-header">
              <span class="skill-name">{{ skill.name }}</span>
              <span class="skill-percentage">{{ skill.percentage }}%</span>
            </div>
            <div class="progress-bar">
              <div class="fill" :style="{ width: skill.percentage + '%' }"></div>
            </div>
            <p class="skill-details">{{ $t('skills.' + skill.details)}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgrammingLangPage',
  data() {
    return {
      skills: [
        { name: 'Java', percentage: 90, details: 'java' },
        { name: 'JavaScript', percentage: 90, details: 'js' },
        { name: 'Spring', percentage: 90, details: 'spring' },
        { name: 'React', percentage: 85, details: 'react' },
        { name: 'Database', percentage: 85, details: 'db' },
        { name: 'Python', percentage: 75, details: 'python' },
        { name: 'Angular', percentage: 70, details: 'angular' },
        { name: 'Flutter and Dart', percentage: 70, details: 'flutter' },
        { name: 'C#', percentage: 55, details: 'cs' }
      ]
    };
  }
};
</script>

<style scoped>

.title {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #34495e;
  text-align: center;
  word-wrap: break-word;
}

.skillBarContainer {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
}

.skill-bar {
  background-color: #ececec;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  box-sizing: border-box;
  padding: 15px;
}

.skill-bar:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.skill-header {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.fill {
  height: 100%;
  background-color: #3498db;
  transition: width 0.4s ease;
}

.skill-details {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin: 0;
  overflow-wrap: break-word;
}
</style>
