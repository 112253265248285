<template>
  <div id="home" class="w-full bg-gray-100 py-6 pb-4">
    <div class="container flex flex-col items-center justify-center px-4 pt-6">
      <!-- Profile Picture and Welcome Message -->
      <!-- <img class="rounded-full shadow-lg mb-6 w-40 h-40 sm:w-48 sm:h-48 lg:w-60 lg:h-60" src="../assets/MePicture.png"
        alt="Image of creator" /> -->
      <h1 class="text-3xl sm:text-4xl lg:text-5xl font-bold text-green-500 underline mb-4 text-center">
        {{ $t('HomeComponent.welcome') }}
      </h1>

      <!-- About Me Section -->
      <div class="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
        <h1 class="text-2xl font-semibold text-gray-800 mb-4 text-center sm:text-left">
          {{ $t('HomeComponent.about') }}
        </h1>
        <p class="text-gray-600 mb-6">
          {{ $t('HomeComponent.aboutMeMessage') }}
        </p>

        <!-- Ambitions Section -->
        <h2 class="text-xl font-semibold text-gray-800 mb-4">{{ $t('HomeComponent.myAmbitions') }}</h2>
        <p class="text-gray-600">
          {{ $t('HomeComponent.myAmbitionsMessage') }}
        </p>
      </div>
      
      <h2 class="text-2xl font-semibold text-gray-800 mb-6 mt-6">{{ $t('HomeComponent.studies') }}</h2>
      <!-- Studies Section -->
      <div class="w-full max-w-4xl space-y-8">
        <!-- Cégep André-Laurendeau -->
        <div
          class="flex flex-col sm:flex-row items-center sm:space-x-6 bg-white hover:bg-gray-50 transition-all p-6 rounded-lg shadow-lg hover:shadow-2xl">
          <img class="rounded-full shadow-lg w-24 h-24 mb-4 sm:mb-0" src="../assets/andre.png" alt="Cegep logo" />
          <div>
            <h3 class="text-2xl font-semibold text-gray-800 mb-2">Cégep André-Laurendeau</h3>
            <p class="text-gray-600 mb-4">
              {{ $t('HomeComponent.andreLaurendeau.message') }}
            </p>
            <ul class="list-disc ml-6 text-gray-600">
              <li>{{ $t('HomeComponent.andreLaurendeau.ul.program') }}</li>
              <li>{{ $t('HomeComponent.andreLaurendeau.ul.specialization') }}</li>
              <li>{{ $t('HomeComponent.andreLaurendeau.ul.keyCourses') }}</li>
              <li>{{ $t('HomeComponent.andreLaurendeau.ul.projects') }}</li>
            </ul>
            <p class="text-gray-600 mt-4"><strong>{{ $t('HomeComponent.andreLaurendeau.gradMessage') }}</strong>{{ $t('HomeComponent.andreLaurendeau.gradDate') }}</p>
          </div>
        </div>

        <!-- École de technologie supérieure -->
        <div
          class="flex flex-col sm:flex-row items-center sm:space-x-6 bg-white hover:bg-gray-50 transition-all p-6 rounded-lg shadow-lg hover:shadow-2xl">
          <img class="rounded-full shadow-lg w-24 h-24 mb-4 sm:mb-0" src="../assets/ets.jpg" alt="University logo" />
          <div>
            <h3 class="text-2xl font-semibold text-gray-800 mb-2">École de technologie supérieure</h3>
            <p class="text-gray-600 mb-4">
              {{ $t('HomeComponent.ets.message') }}
            </p>
            <ul class="list-disc ml-6 text-gray-600">
              <li>{{ $t('HomeComponent.ets.ul.program') }}</li>
              <li>{{ $t('HomeComponent.ets.ul.keyCourses') }}</li>
              <li>{{ $t('HomeComponent.ets.ul.projects') }}</li>
            </ul>
            <p class="text-gray-600 mt-4"><strong>{{ $t('HomeComponent.ets.gradMessage') }}</strong>{{ $t('HomeComponent.ets.gradDate') }}</p>
          </div>
        </div>
      </div>

      <!-- Work Experience Section -->
      <h2 class="text-2xl font-semibold text-gray-800 mb-6 mt-6">{{ $t('HomeComponent.workExperience') }}</h2>
      <div class="w-full max-w-4xl space-y-8">
        <!-- CyFrame ERP Solutions -->
        <div
          class="flex flex-col sm:flex-row items-center sm:space-x-6 bg-white hover:bg-gray-50 transition-all p-6 rounded-lg shadow-lg hover:shadow-2xl">
          <img class="rounded-full shadow-lg w-24 h-24 mb-4 sm:mb-0" src="../assets/cyframe.png" alt="Cyframe logo" />
          <div>
            <h3 class="text-2xl font-semibold text-gray-800 mb-2">CyFrame ERP Solutions</h3>
            <p class="text-lg text-gray-600 mb-4">
              {{ $t('HomeComponent.cyframe.message') }}
            </p>
            <ul class="list-disc ml-6 text-gray-600">
              <li>{{ $t('HomeComponent.cyframe.ul.l1') }}</li>
              <li>{{ $t('HomeComponent.cyframe.ul.l2') }}</li>
              <li>{{ $t('HomeComponent.cyframe.ul.l3') }}</li>
            </ul>
          </div>
        </div>
      </div>


      <h2 class="text-xl font-semibold text-gray-800 mb-6 mt-6">{{ $t('HomeComponent.languageAndTech') }}</h2>
      <div class="w-full max-w-4xl space-y-8">
        <div class="flex flex-col sm:flex-row items-center sm:space-x-6 bg-white hover:bg-gray-50 transition-all p-6 rounded-lg shadow-lg hover:shadow-2xl overflow-hidden">
          <div class="relative overflow-hidden">
            <!-- First Row of Moving Images -->
            <div class="flex items-center space-x-8 animate-slide">
              <img v-for="(image, index) in images" :key="index" :src="image"
                class="w-20 h-20 sm:w-24 sm:h-24 rounded shadow" alt="Skill logo" />
              <!-- Duplicate images for seamless loop -->
              <img v-for="(image, index) in images" :key="'duplicate-' + index" :src="image"
                class="w-20 h-20 sm:w-24 sm:h-24 rounded shadow" alt="Skill logo" />
            </div>

            <!-- Second Row of Moving Images -->
            <div class="flex items-center space-x-8 mt-4 mb-4 animate-slide-reverse">
              <img v-for="(reverseImage, index) in reverseImages" :key="'second-' + index" :src="reverseImage"
                class="w-20 h-20 sm:w-24 sm:h-24 rounded shadow" alt="Skill logo" />
              <!-- Duplicate images for seamless loop -->
              <img v-for="(image, index) in images" :key="'second-duplicate-' + index" :src="image"
                class="w-20 h-20 sm:w-24 sm:h-24 rounded shadow" alt="Skill logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
  setup() {
    function importAll(r) {
      return r.keys().map(r);
    }

    const images = importAll(require.context("/src/assets/language", false, /\.(png|jpe?g|svg)$/));
    const reverseImages = images.slice().reverse();
    return {
      images,
      reverseImages
    };
  },
};
</script>

<style scoped>
@keyframes slide {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-reverse {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-slide {
  display: flex;
  animation: 15s linear 1s infinite reverse both slide;
}

.animate-slide-reverse {
  display: flex;
  animation: 15s linear 1s infinite slide-reverse;
}
</style>
